import 'shims';

import Loader from 'Loader';

import { ConfAppController } from './ConfAppController';
import ConfAppUI from './ConfAppUI';

import { isWebRtcSupported } from './BrowserSupport';
import { UnsupportedMessage } from './UIComponents';
import WindowHashHandler from './WindowHashHandler';

import parseValidateUserID from './parseValidateUserID';

Loader.load()
  .then(siteConfig => {
    if (!isWebRtcSupported()) {
      document.body.appendChild(
        <UnsupportedMessage />
      );
      return;
    }

    const alwaysListenOnly = !!siteConfig.settings.WEBCALL_ALWAYS_LISTEN_ONLY;
    const hideHostControls = !!siteConfig.settings.WEBCALL_HIDE_HOST_CONTROLS;

    const {
      userIDEnabled,
      userIDRequired,
    } = parseValidateUserID(siteConfig.settings.WEBCALL_VALIDATE_USER_ID);

    const windowHashHandler = new WindowHashHandler();

    const appConfig = {
      wsSipURI: siteConfig.settings.WEBCALL_SIP_URI,
      toUriPrefix: siteConfig.settings.WEBCALL_TO_URI_PREFIX,
      toUriSuffix: siteConfig.settings.WEBCALL_TO_URI_SUFFIX,

      fetchLocationURL: siteConfig.settings.WEBCALL_LOCATION_URL || null,
      fetchRTCConfigurationURL: siteConfig.settings.WEBCALL_RTC_CONFIGURATION_URL || null,
    };

    const formDefaults = {
      conferenceID: '',
      fromName: '',
      email: '',
      userID: '',

      wsSipURI: appConfig.wsSipURI,
      toUriPrefix: appConfig.toUriPrefix,
      toUriSuffix: appConfig.toUriSuffix,
      fetchLocationURL: appConfig.fetchLocationURL,
      fetchRTCConfigurationURL: appConfig.fetchRTCConfigurationURL,
    };

    const ctrl = new ConfAppController({
      defaultConferenceID: siteConfig.settings.WEBCALL_CONFERENCE_ID,
      defaultFromName: siteConfig.settings.WEBCALL_DEFAULT_FROM_NAME,
      defaultEmail: siteConfig.settings.WEBCALL_DEFAULT_EMAIL,
      localStorageKey: 'webCall_conferenceConnectParams',
      alwaysListenOnly,
      enableTestApp: !siteConfig.settings.WEBCALL_DISABLE_TEST_APP && !alwaysListenOnly,
      enableSettings: !siteConfig.settings.WEBCALL_DISABLE_SETTINGS && !alwaysListenOnly,
      disableCommandButtons: siteConfig.settings.WEBCALL_DISABLE_COMMAND_BUTTONS,
      hideHostControls,
      userIDEnabled,
      userIDRequired,
    });

    ctrl.setDebugMode(windowHashHandler.debugMode);
    windowHashHandler.on('debugModeChange', debugMode => ctrl.setDebugMode(debugMode));

    ctrl.setAppConfig(appConfig);
    ctrl.setFormDefaults(formDefaults);
    ctrl.loadStoredParams();

    // override formDefaults
    const { hashParams } = windowHashHandler;
    if (hashParams.id)
      ctrl.formDefaults.conferenceID = hashParams.id;
    if (hashParams.name)
      ctrl.formDefaults.fromName = hashParams.name;
    if (hashParams.email)
      ctrl.formDefaults.email = hashParams.email;
    if (hashParams.userid)
      ctrl.formDefaults.userID = hashParams.userid;

    document.body.append(...(
      <>
        <div class="logo-container" />
        <ConfAppUI ctrl={ctrl} />
      </>
    ));
  })
  .then(() => Loader.setLogo())
  .then(() => Loader.loadComplete())
  .catch(err => Loader.loadError(err));
