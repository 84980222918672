import { Cond, RefFormData, FormText } from 'Components/FormComponents';
import { hook, Hooks } from 'Components/Hooks';

import {
  AppConnectionForm,
  AppConnectButton,
  AppConnectTestCallButton,
  AppConnectionErrorModal,
} from './UIComponents';
import ConfCallUI from './ConfCallUI';
import TestCallUI from './TestCallUI';

import s from './strings';

export default function ConfAppUI({ ctrl }) {
  const connectionForm = new RefFormData();

  const hooks = new Hooks();

  const ctx = {
    hooks,
    ctrl,

    connectionForm,
  };

  const root = (
    <div
      class="pnlWebCallApp"
      class:always-listen-only={ctrl.alwaysListenOnly}
      class:disable-command-buttons={ctrl.disableCommandButtons}
    >
      <AppConnectionErrorModal ctx={ctx} />

      <AppConnectionForm ctx={ctx}>
        <FormText inline form={connectionForm} type="tel" name="conferenceID" label={s.lblConferenceID} />
        <FormText inline form={connectionForm} name="fromName" label={s.lblName} inputAttributes={{ autocomplete: 'name' }} />
        <FormText inline form={connectionForm} type="email" name="email" label={s.lblEmail} inputAttributes={{ autocomplete: 'email' }} />
        <FormText inline form={connectionForm} type="tel" name="userID" label={s.lblUserID} />
        <div use:hook={hooks.show('debugMode')}>
          <FormText inline form={connectionForm} name="wsSipURI" label={s.lblWebSocketURI} />
          <FormText inline form={connectionForm} name="toUriPrefix" label={s.lblToUriPrefix} />
          <FormText inline form={connectionForm} name="toUriSuffix" label={s.lblToUriSuffix} />
          <FormText inline form={connectionForm} name="fetchLocationURL" label="fetchLocationURL" />
          <FormText inline form={connectionForm} name="fetchRTCConfigurationURL" label="fetchRTCConfigurationURL" />
        </div>

        <div class="disconnected-menu">
          <button
            type="button"
            class="btn btn-link"
            use:hook={hooks.show('debugMode')}
            onclick={() => ctrl.clearLocalStorage()}
          >
            {s.DebugView.lblClearLocalStorage}
          </button>

          <Cond test={ctrl.enableSettings}>
            <button type="button" class="btn btn-link" onclick={() => ctrl.openSettings()}>{s.lblSettings}</button>
          </Cond>

          <Cond test={ctrl.enableTestApp}>
            <AppConnectTestCallButton ctx={ctx} />
          </Cond>

          <AppConnectButton ctx={ctx} />
        </div>
      </AppConnectionForm>

      <div use:hook={hooks.show('currentCall', val => val === 'conf')}>
        <ConfCallUI ctrl={ctrl.callController} />
      </div>
      <Cond test={ctrl.enableTestApp}>
        <div use:hook={hooks.show('currentCall', val => val === 'test')}>
          <TestCallUI ctrl={ctrl.testCallController}  />
        </div>
      </Cond>
    </div>
  );

  connectionForm.setVisibility('conferenceID', ctrl.defaultConferenceID === null);
  connectionForm.setVisibility('fromName', ctrl.defaultFromName === null);
  connectionForm.setVisibility('email', ctrl.defaultEmail === null);
  connectionForm.setVisibility('userID', ctrl.userIDEnabled);

  hooks.add('formDefaults', values => connectionForm.setValues(values));

  ctrl.on('update', () => hooks.run(ctrl));
  hooks.run(ctrl);

  return root;
}
