import EventEmitter from 'events';

import parseQueryString from 'Browser/parseQueryString';

export default class WindowHashHandler extends EventEmitter {
  constructor() {
    super();

    this._hashParams = {};
    this._debugMode = false;
    if (window.location.hash.length) {
      this._parseHashParams();
    }

    window.addEventListener('hashchange', () => {
      const prevDebugMode = this._debugMode;
      this._parseHashParams();
      if (this._debugMode !== prevDebugMode) {
        this.emit('debugModeChange', this._debugMode);
      }
    });
  }

  _parseHashParams() {
    const tmp = parseQueryString(window.location.hash.substr(1));

    this._hashParams = {};
    for (let i in tmp)
      this._hashParams[i.toLowerCase()] = tmp[i];

    this._debugMode = !!('debug' in this._hashParams && (this._hashParams.debug || this._hashParams.debug === ''));
  }

  get hashParams() {
    return this._hashParams;
  }

  get debugMode() {
    return this._debugMode;
  }
}
